import axios from "axios";

const authServiceUrl = process.env.REACT_APP_AUTH_SERVICE_BASE_URL;
const customerServiceUrl = process.env.REACT_APP_CUSTOMER_SERVICE_BASE_URL;
const vendorServiceUrl = process.env.REACT_APP_VENDOR_SERVICE_BASE_URL;
const adminServiceUrl = process.env.REACT_APP_ADMIN_SERVICE_BASE_URL;
const orderServiceUrl = process.env.REACT_APP_ORDER_SERVICE_BASE_URL;

axios.defaults.withCredentials = true;
export const axiosAuthServiceInstance = axios.create({
  baseURL:
    window.location.hostname === "localhost"
      ? "http://localhost:8080"
      : `https://${window.location.hostname}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosCustomerServiceInstance = axios.create({
  baseURL:
    window.location.hostname === "localhost"
      ? "http://localhost:8081"
      : `https://${window.location.hostname}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosVendorServiceInstance = axios.create({
  baseURL:
    window.location.hostname === "localhost"
      ? "http://localhost:8082"
      : `https://${window.location.hostname}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosAdminServiceInstance = axios.create({
  baseURL: adminServiceUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosOrderServiceInstance = axios.create({
  baseURL:
    window.location.hostname === "localhost"
      ? "http://localhost:8083/order-service"
      : `https://${window.location.hostname}/order-service`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosAlternateAuthServiceInstance = axios.create({
  baseURL:
    window.location.hostname === "localhost"
      ? "http://localhost:8080"
      : `https://${window.location.hostname}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosAlternateCustomerServiceInstance = axios.create({
  baseURL:
    window.location.hostname === "localhost"
      ? "http://localhost:8081"
      : `https://${window.location.hostname}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosAlternateVendorServiceInstance = axios.create({
  baseURL:
    window.location.hostname === "localhost"
      ? "http://localhost:8082"
      : `https://${window.location.hostname}`,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosCustomerServiceInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const currentState = {
        pathname: window.location.pathname,
        state: window.history.state.usr,
      };
      sessionStorage.setItem(
        "previousLocationState",
        JSON.stringify(currentState)
      );

      window.location.href = "/auth/loginCustomer/email";
    }
    return Promise.reject(error);
  }
);

axiosVendorServiceInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const currentState = {
        pathname: window.location.pathname,
        state: window.history.state.usr,
      };
      sessionStorage.setItem(
        "previousLocationState",
        JSON.stringify(currentState)
      );

      window.location.href = "/auth/loginVendor/email";
    }
    return Promise.reject(error);
  }
);

axiosOrderServiceInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const currentState = {
        pathname: window.location.pathname,
        state: window.history.state?.locationState,
      };
      sessionStorage.setItem(
        "previousLocationState",
        JSON.stringify(currentState)
      );

      window.location.href = "/auth/loginVendor/email";
    }
    return Promise.reject(error);
  }
);

axiosAuthServiceInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const currentState = {
        pathname: window.location.pathname,
        state: window.history.state.usr,
      };
      sessionStorage.setItem(
        "previousLocationState",
        JSON.stringify(currentState)
      );

      window.location.href = [
        "/auth/loginAdmin",
        "auth/loginVendor/email",
      ].includes(window.location.pathname)
        ? window.location.pathname
        : "/auth/loginCustomer/email";
    }
    return Promise.reject(error);
  }
);

axiosAlternateAuthServiceInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const currentState = {
        pathname: window.location.pathname,
        state: window.history.state?.locationState,
      };
      sessionStorage.setItem(
        "previousLocationState",
        JSON.stringify(currentState)
      );

      window.location.href = "/auth/loginVendor/email";
    }
    return Promise.reject(error);
  }
);

axiosAlternateCustomerServiceInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const currentState = {
        pathname: window.location.pathname,
        state: window.history.state?.locationState,
      };
      sessionStorage.setItem(
        "previousLocationState",
        JSON.stringify(currentState)
      );

      window.location.href = "/auth/loginVendor/email";
    }
    return Promise.reject(error);
  }
);

axiosAlternateVendorServiceInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const currentState = {
        pathname: window.location.pathname,
        state: window.history.state?.locationState,
      };
      sessionStorage.setItem(
        "previousLocationState",
        JSON.stringify(currentState)
      );

      window.location.href = "/auth/loginVendor/email";
    }
    return Promise.reject(error);
  }
);
