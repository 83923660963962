import { createSlice } from "@reduxjs/toolkit";

const vendorItemCategoriesWithItemsSlice = createSlice({
  name: "vendorItemCategoriesWithItems",
  initialState: { value: null },
  reducers: {
    setVendorItemCategoriesWithItems: (state, action) => {
      state.value = action.payload;
    },
  },
});

const vendorTotalActiveItemsCountSlice = createSlice({
  name: "vendorTotalActiveItemsCount",
  initialState: { value: 0 },
  reducers: {
    setVendorTotalActiveItemsCount: (state, action) => {
      state.value = action.payload;
    },
  },
});

const vendorTotalActiveInactiveItemsCountSlice = createSlice({
  name: "vendorTotalActiveInactiveItemsCount",
  initialState: { value: 0 },
  reducers: {
    setVendorTotalActiveInactiveItemsCount: (state, action) => {
      state.value = action.payload;
    },
  },
});

const customerItemCategoriesWithItemsSlice = createSlice({
  name: "customerItemCategoriesWithItems",
  initialState: { value: null },
  reducers: {
    setCustomerItemCategoriesWithItems: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Export actions from both slices
export const { setVendorItemCategoriesWithItems } =
  vendorItemCategoriesWithItemsSlice.actions;
export const { setVendorTotalActiveItemsCount } =
  vendorTotalActiveItemsCountSlice.actions;
export const { setVendorTotalActiveInactiveItemsCount } =
  vendorTotalActiveInactiveItemsCountSlice.actions;
export const { setCustomerItemCategoriesWithItems } =
  customerItemCategoriesWithItemsSlice.actions;

// Combine reducers if necessary (typically done in store setup)
export default {
  vendorItemCategoriesWithItems: vendorItemCategoriesWithItemsSlice.reducer,
  vendorTotalActiveItemsCount: vendorTotalActiveItemsCountSlice.reducer,
  vendorTotalActiveInactiveItemsCount:
    vendorTotalActiveInactiveItemsCountSlice.reducer,
  customerItemCategoriesWithItems: customerItemCategoriesWithItemsSlice.reducer,
};
