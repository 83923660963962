export default function Feasto(){
    return(
        <>
        <svg width="180" height="40" viewBox="0 0 180 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_824_7981)">
    <path d="M7.46769 10.2569V17.7902H20.4851C21.0156 18.5639 21.2605 19.7856 21.2605 20.9665C21.2605 22.1474 20.934 23.3283 20.4851 23.8984H7.46769V35.4631C6.3659 35.7481 5.10088 35.911 3.71344 35.911C2.326 35.911 1.10179 35.7481 0 35.4631V3.98584H22.4439C22.9744 4.75954 23.2192 6.02189 23.2192 7.16207C23.2192 8.42442 22.9336 9.64605 22.4439 10.2569H7.46769Z" fill="#E52D3C"/>
    <path d="M49.6598 32.8977C49.6598 34.1193 49.3741 35.3002 48.8844 35.911H26.1549V3.98584H48.4356C48.9661 4.75954 49.2109 5.98116 49.2109 7.16207C49.2109 8.42442 48.9253 9.60532 48.4356 10.1754H33.4594V16.8536H47.5378C48.0275 17.5866 48.2723 18.7268 48.2723 19.8263C48.2723 21.0072 47.9867 22.1066 47.5378 22.6767H33.4594V29.7215H48.9253C49.4557 30.4951 49.6598 31.7575 49.6598 32.8977Z" fill="#E52D3C"/>
    <path d="M82.4687 35.4224C81.4485 35.7074 80.7548 35.911 78.6736 35.911C77.0006 35.911 75.858 35.626 74.8786 35.4224L72.879 29.355H60.5145L58.4741 35.4224C57.5356 35.626 56.4746 35.911 54.8015 35.911C52.8428 35.911 52.1082 35.7074 51.1697 35.4224L62.3916 4.51521C63.8199 4.108 65.1257 3.98584 66.7988 3.98584C68.3086 3.98584 70.0225 4.14872 71.2468 4.51521L82.4687 35.4224ZM71.1243 24.0205L66.7172 10.827L62.31 24.0205H71.1243Z" fill="#E52D3C"/>
    <path d="M86.1845 33.9972C83.8585 32.3684 82.5935 29.8844 82.6751 26.6675C83.7361 26.301 85.0419 26.0974 86.2253 26.0974C87.5311 26.0974 88.6329 26.301 89.7347 26.6675C89.7347 28.1334 90.1836 28.9885 90.9997 29.6401C91.9383 30.4138 93.4482 30.8617 95.9782 30.8617C98.5082 30.8617 99.9773 30.4138 100.916 29.6401C101.691 28.9885 102.14 28.0927 102.14 27.0339C102.14 24.7128 100.426 23.817 97.3248 23.1655L93.5706 22.4325C88.1432 21.3737 83.8993 19.4599 83.8993 13.1481C83.8993 10.3791 84.9603 7.77295 87.0415 6.02195C89.041 4.39311 91.9791 3.25293 96.5903 3.25293C100.549 3.25293 103.65 4.18951 105.813 5.73691C107.731 7.12142 108.873 9.07602 108.873 11.4786C108.873 11.845 108.832 12.4151 108.751 12.8631C108.016 13.1481 107.078 13.3517 105.649 13.3517C104.221 13.3517 102.997 13.1074 102.181 12.8631C102.222 12.578 102.222 12.4151 102.222 12.2522C102.222 11.4786 101.691 10.4605 100.916 9.89044C99.9773 9.19818 98.6715 8.79097 96.9984 8.79097C94.6316 8.79097 93.3257 9.11674 92.3464 9.809C91.4894 10.4198 90.9589 11.2749 90.9589 12.4559C90.9589 14.4512 92.4688 15.6321 95.2845 16.2022L100.834 17.3424C106.058 18.4418 109.771 20.7222 109.771 26.3824C109.771 29.7215 108.261 32.2462 106.139 33.8751C103.936 35.5446 100.834 36.4812 96.223 36.4812C91.6118 36.4812 88.4289 35.5853 86.1845 33.9972Z" fill="#E52D3C"/>
    <path d="M136.701 10.1754H127.765V35.3409C126.663 35.7074 125.52 35.9518 124.092 35.9518C122.664 35.9518 121.521 35.7074 120.46 35.3409V10.1754H111.523C111.034 9.52388 110.707 8.3837 110.707 7.12135C110.707 5.98116 110.993 4.75954 111.523 3.98584H136.701C137.273 4.75954 137.558 5.98116 137.558 7.12135C137.558 8.3837 137.232 9.52388 136.701 10.1754Z" fill="#E52D3C"/>
    <path d="M144.637 19.9484C144.637 9.5646 150.513 3.29358 159.981 3.29358C169.448 3.29358 175.12 9.5646 175.12 19.9484C175.12 30.3323 169.366 36.6033 159.981 36.6033C150.595 36.6033 144.637 30.2101 144.637 19.9484ZM167.734 19.9484C167.734 13.3924 164.837 9.52388 159.981 9.52388C155.124 9.52388 152.064 13.3924 152.064 19.9484C152.064 26.5045 155.043 30.373 159.981 30.373C164.918 30.373 167.734 26.4231 167.734 19.9484Z" fill="#E52D3C"/>
    <path d="M159.753 39.8634C148.607 40.2514 139.74 30.8329 139.759 19.9724C139.776 9.77782 147.261 0.0479202 159.983 1.32867e-05C171.315 -0.0407076 179.964 9.11192 180 20.061C180.036 30.5215 170.84 40.5485 159.753 39.8634ZM140.499 19.9269C140.273 30.9934 149.147 39.3604 159.793 39.3963C170.847 39.4322 179.263 30.8233 179.311 19.9413C179.361 8.62326 170.194 0.512618 159.87 0.49106C149.784 0.471897 140.268 8.40289 140.496 19.9269H140.499Z" fill="#E52D3C"/>
    </g>
    <defs>
    <clipPath id="clip0_824_7981">
    <rect width="180" height="39.8969" fill="white"/>
    </clipPath>
    </defs>
    </svg>
        </>
    )
    

}


 