import React from "react";
import styled from "styled-components";
import Feasto from '../assets/feasto'; // Update with the correct path to your logo file

const Loader = () => {
  return (
    <StyledWrapper>
      <div className="loader-container">
      <div className="logo-container">
          <Feasto />
        </div>
        <section className="dots-container" style={{marginTop:"24px"}}>
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </section>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full screen height */
  width: 100vw; /* Full screen width */
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff; /* Optional: add a background color */

  .loader-container {
    text-align: center;
  }

  .logo-container {
    transform: scale(0.8); /* Scale down the container */
    display: inline-block; /* Ensure the container wraps tightly around the SVG */
  }

  .dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dot {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #b3d4fc;
    animation: pulse 1.5s infinite ease-in-out;
  }

  .dot:last-child {
    margin-right: 0;
  }

  .dot:nth-child(1) {
    animation-delay: -0.3s;
  }

  .dot:nth-child(2) {
    animation-delay: -0.1s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.1s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      background-color: #FFC8CC;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }

    50% {
      transform: scale(1.2);
      background-color: #FC5865;
      box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
    }

    100% {
      transform: scale(0.8);
      background-color: #E52D3C;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }
  }
`;

export default Loader;