import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import posReducer from "./../features/pos"; // Import the combined reducers
import itemsReducer from "./../features/items";

// Create a persist configuration
const persistConfig = {
  key: "root",
  storage,
};

// Combine the reducers into a single reducer
const rootReducer = {
  posCart: posReducer.posCart,
  allOffers: posReducer.allOffers,
  posCharges: posReducer.posCharges,
  itemIdsAndApplicableOfferIds: posReducer.itemIdsAndApplicableOfferIds,
  selectedOffer: posReducer.selectedOffer,
  posChargeIds: posReducer.posChargeIds,
  customerContactNumber: posReducer.customerContactNumber,
  customerName: posReducer.customerName,
  discount: posReducer.discount,
  totalPOSCharge: posReducer.totalPOSCharge,
  vendorItemCategoriesWithItems: itemsReducer.vendorItemCategoriesWithItems,
  vendorTotalActiveItemsCount: itemsReducer.vendorTotalActiveItemsCount,
  vendorTotalActiveInactiveItemsCount:
    itemsReducer.vendorTotalActiveInactiveItemsCount,
  customerItemCategoriesWithItems: itemsReducer.customerItemCategoriesWithItems,
};

// Apply persistReducer to the combined reducer
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers(rootReducer)
);

// Configure the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
});

// Create the persistor
export const persistor = persistStore(store);
