import {
  Box,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomVendorContainer,
  VendorSubTypography,
} from "../../Utils/VendorStyledTags";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { axiosVendorServiceInstance } from "../../../../services/api";
import { toast } from "react-toastify";
import TopNavBarWebView from "../../../../layouts/TopNavBarWebView";
import { VendorManageBankDetails } from "./VendorManageBankDetails";
import { VendorManageDigitalPaymentMethods } from "./VendorManageDigitalPaymentMethods";
import { useVendor } from "../../../../contexts/VendorContext";

export const VendorManagePaymentMethodScreen = ({ themeColor }) => {
  const isLargeScreen = useMediaQuery("(min-width:1349px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:710px) and (max-width:1349px)"
  );
  const { bankDetail } = useVendor();

  const isBoth = isLargeScreen || isMediumScreen;
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(location.state ? location.state.selectedTab : 0);
  const [vendorSubscriptionDetails, setVendorSubscriptionDetails] = useState(
    {}
  );

  useEffect(() => {
    const getCurrentSubscriptionPlan = async () => {
      try {
        const response = await axiosVendorServiceInstance.get(
          `vendor-service/view-profile?userId=${JSON.parse(
            localStorage.getItem("userId")
          )}`
        );

        setVendorSubscriptionDetails(response.data.data);

        if (
          response.data.data.subscriptionPlan !==
          JSON.parse(localStorage.getItem("user"))["subscriptionPlan"]
        ) {
          const user = JSON.parse(localStorage.getItem("user"));
          user.subscriptionPlan = response.data.data.subscriptionPlan;
          localStorage.setItem("user", JSON.stringify(user));
        }
      } catch (err) {
        if (err.response === undefined) {
          alert("Please Try Again Later!");
        } else {
          const errmessage = err.response.data.error;
          toast.error(errmessage);
        }
      }
    };
    getCurrentSubscriptionPlan();
  }, []);

  const handleClickManageDigitalPayments = () => {
    if (JSON.parse(localStorage.getItem("user")).subscriptionPlan === "FREE") {
      toast.error(
        "UPGRADE now to unlock this feature and enhance your experience!"
      );
      if (isBoth) {
        setSelectedPaymentMethod(1);
      }
    } else {
      if (isBoth) {
        setSelectedPaymentMethod(1);
      } else {
        navigate("/vendor/digitalPaymentMethods", {
          state: location.state,
        });
      }
    }
  };

  const handleUpgradeButtonClick = () => {
    navigate("/vendor/upgradeSubscription", {
      state: { originPath: "/vendor/vendorManagePaymentMethod" },
    });
  };

  const handleClickBackIcon = () => {
    navigate("/vendor/vendorService");
  };

  const handleClickManageBankDetails = () => {
    if (isBoth) {
      setSelectedPaymentMethod(0);
    } else {
      navigate("/vendor/vendorManageBankDetails", {
        state: bankDetail,
      });
    }
  };
  return (
    <>
      {(isLargeScreen || isMediumScreen) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            background: "var(--gray-50, #F9FAFB)",
          }}
        >
          <TopNavBarWebView index={6} />
          <Grid
            container
            sx={{ display: "flex", flexDirection: "row", padding: "0px 40px" }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: isLargeScreen
                  ? `40px calc((100% - 1360px) / 2) 40px calc(((100% - 1360px) / 2) + 400px)`
                  : "40px auto",
                border: "1px solid var(--gray-200, #E5E7EB)",
                background: "#FFF",
                borderRadius: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--gray-900, #111827)",
                  fontFamily: "Poppins",
                  fontSize: "28px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "130%",
                  padding: "16px",
                  textAlign: "left",
                  borderBottom: "1px solid var(--gray-200, #E5E7EB)",
                }}
              >
                Payment methods{" "}
              </Typography>

              <Grid
                sx={{
                  display: "flex",
                  marginRight: "1px solid black",
                }}
              >
                <Container
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "306px",
                    padding: "24px 16px",
                    cursor: "pointer",
                    flexDirection: "column",
                    gap: "16px",
                    borderRight: "1px solid var(--gray-200, #E5E7EB)",
                  }}
                >
                  <Button
                    onClick={handleClickManageBankDetails}
                    variant="contained"
                    style={{
                      backgroundColor:
                        selectedPaymentMethod === 0 ? themeColor : "#FFFF",
                      color:
                        selectedPaymentMethod === 0
                          ? "var(--white, #FFF)"
                          : "var(--gray-700, #374151)",
                      width: "100%",
                      textTransform: "none",
                      justifyContent: "space-between",
                      gap: "8px",
                      padding: "8px 10px",
                      boxShadow: "none",
                    }}
                  >
                    Bank accounts
                  </Button>
                  <Button
                    onClick={handleClickManageDigitalPayments}
                    variant="contained"
                    style={{
                      backgroundColor:
                        selectedPaymentMethod === 1 ? themeColor : "#FFFF",

                      color:
                        selectedPaymentMethod === 1
                          ? "var(--white, #FFF)"
                          : "var(--gray-700, #374151)",
                      width: "100%",
                      textTransform: "none",
                      justifyContent: "space-between",
                      gap: "8px",
                      padding: "8px 10px",
                      boxShadow: "none",
                    }}
                  >
                    Digital payments{" "}
                  </Button>
                </Container>{" "}
                <Container
                  maxWidth="sm"
                  style={{
                    width: "100%",
                    background: "#FFF",
                    margin: "24px 12px",
                    padding: "0px",
                  }}
                >
                  {" "}
                  {selectedPaymentMethod === 0 && (
                    <VendorManageBankDetails bankDetail={bankDetail} />
                  )}
                  {selectedPaymentMethod === 1 && (
                    <VendorManageDigitalPaymentMethods
                      digitalPayments={location.state}
                    />
                  )}
                </Container>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      <CssBaseline />
      <main>
        <CustomVendorContainer>
          <Container>
            {!isBoth && (
              <Container maxWidth="sm">
                <Container
                  maxWidth="sm"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    top: "0",
                    right: 0,
                    left: 0,
                    position: "fixed",
                    zIndex: 1000,
                    backgroundColor: "white",
                  }}
                >
                  <IconButton
                    color="inherit"
                    onClick={handleClickBackIcon}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 30,
                      zIndex: 1001,
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>

                  <VendorSubTypography>Payment Methods</VendorSubTypography>
                </Container>

                <Box
                  sx={{
                    marginTop: "70px",
                    display: "flex",
                    width: "100%",
                    height: "73px",
                    borderBottom: "1px solid #BBBBBB",
                    padding: "16px 12px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickManageBankDetails}
                >
                  <AccountBalanceTwoToneIcon sx={{ color: "#000" }} />
                  <Typography
                    sx={{
                      marginLeft: "32px",
                      fontSize: "14px",
                      fontWeight: "550",
                      color: "#000",
                    }}
                  >
                    Bank Accounts
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "73px",
                    borderBottom: "1px solid #BBBBBB",
                    padding: "16px 12px",
                    alignItems: "center",
                  }}
                >
                  <CreditCardIcon sx={{ color: "#000" }} />
                  <Grid
                    container
                    xs={12}
                    sx={{
                      width: "100%",
                      cursor: "pointer",
                      justifyContent: "flex-start",
                      textAlign: "left",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={9}
                      onClick={handleClickManageDigitalPayments}
                    >
                      <Typography
                        sx={{
                          marginLeft: "32px",
                          fontSize: "14px",
                          fontWeight: "550",
                          color: "#000",
                        }}
                      >
                        Digital Payments
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        marginRight: "auto",
                      }}
                    >
                      <Button
                        onClick={handleUpgradeButtonClick}
                        style={{
                          display:
                            vendorSubscriptionDetails &&
                            vendorSubscriptionDetails.subscriptionPlan !==
                              "FREE"
                              ? "none"
                              : "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginLeft: "-15px",
                          backgroundImage:
                            "linear-gradient(to right, #EC3B43 0%, #F15B61 100%)",
                          height: "40px",
                          width: "110px",
                          borderRadius: "8px",
                          border: "1px solid var(--lighter-grey, #BDBDBD)",
                          padding: "10px 15px",
                        }}
                      >
                        <ElectricBoltIcon
                          sx={{ color: "#FFFFFF", fontSize: 20 }}
                        />
                        <Typography sx={{ color: "white", fontSize: 12 }}>
                          Upgrade
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            )}
            {/* <div style={{ marginTop: "10px" }}>
              {!isBoth && <VendorBottomNavbar clickedIndex={2} />}
            </div> */}
          </Container>
        </CustomVendorContainer>
      </main>
    </>
  );
};

