import React from "react";
import {
  CustomVendorContainer,
  VendorServicePortalFrame,
  ViewStatusBlock,
} from "../../Utils/VendorStyledTags";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Switch,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { VENDOR_PORTAL_TITTLE } from "../../Utils/VendorStyledObjects";
import { axiosVendorServiceInstance } from "../../../../services/api";
import { useState } from "react";
import { toast } from "react-toastify";
import ManageTakeAwayModeConfirmation from "../Section/DialogBoxes/ManageTakeAwayModeConfirmation";
import ManageDeliveryModeConfirmation from "../Section/DialogBoxes/ManageDeliveryModeConfirmation";
import ManageShopAvailabiliyConfirmation from "../Section/DialogBoxes/ManageShopAvailabiliyConfirmation";
import ManageDineinModeConfirmation from "../Section/DialogBoxes/ManageDineinModeConfirmation";
import { VENDOR_CATEGORY } from "../../../../DomainConstants";
import CircleIcon from "@mui/icons-material/Circle";
import Store from "@mui/icons-material/Storefront";
import Bag from "@mui/icons-material/LocalMallOutlined";
import Restaurant from "@mui/icons-material/RestaurantOutlined";
import Delivery from "@mui/icons-material/LocalShippingOutlined";
import TopNavBarWebView from "../../../../layouts/TopNavBarWebView";
import { useVendor } from "../../../../contexts/VendorContext";

export const VendorManageDispatchMode = () => {
  const isLargeScreen = useMediaQuery("(min-width:1069px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:700px) and (max-width:1069px)"
  );
  const isBoth = isLargeScreen || isMediumScreen;
  const { dispatchedModes } = useVendor();
  const navigate = useNavigate();

  const vendor = JSON.parse(localStorage.getItem("user"));
  vendor.dispatchModes = dispatchedModes;
  sessionStorage.setItem("dispatchModes", JSON.stringify(vendor.dispatchModes));
  const [isShopActive, setIsShopActive] = useState(
    vendor.status === "ACTIVE" ? true : false
  );

  const [isDeliveryActive, setIsDeliveryActive] = useState(
    vendor.dispatchModes.includes("DELIVERY") ? true : false
  );
  const [isTakeawayActive, setIsTakeawayActive] = useState(
    vendor.dispatchModes.includes("TAKE_AWAY") ? true : false
  );
  const [isDineInActive, setIsDineInActive] = useState(
    vendor.dispatchModes.includes("DINE_IN") ? true : false
  );

  const [isShopDialogOpen, setIsShopDialogOpen] = useState(false);
  const [isTakeAwayDialogOpen, setIsTakeAwayDialogOpen] = useState(false);
  const [isDeliveryDialogOpen, setIsDeliveryDialogOpen] = useState(false);
  const [isDineinDialogOpen, setIsDineinDialogOpen] = useState(false);

  const confimationStatusDataForShopAvailability = {
    isDialogOpen: true,
    dialogHeading: isShopActive === true ? "Want to Close?" : "Want to Open?",
    dialogContent:
      isShopActive === true
        ? "Are you sure you want to close your shop? By confirming, your shop will no longer be accessible to customers."
        : "Are you sure you want to open your shop? By confirming, your shop will be available to customers again.",
    backgroundColor: isShopActive === true ? "#FFEEEE" : "#ECFFF0D4",
  };

  const confimationStatusDataForStartOrPauseTakeaway = {
    isDialogOpen: true,
    dialogHeading:
      isTakeawayActive === true
        ? "Want to Stop store pickup?"
        : "Want to Start store pickup?",
    dialogContent:
      isTakeawayActive === true
        ? "Are you sure you want to stop offering store pickup? By confirming, customers will no longer be able to place take away orders."
        : "Are you sure you want to start offering store pickup? By confirming, customers will be able to place take away orders from your shop.",
    backgroundColor: isTakeawayActive === true ? "#FFEEEE" : "#ECFFF0D4",
  };

  const confimationStatusDataForStartOrPauseDinein = {
    isDialogOpen: true,
    dialogHeading:
      isDineInActive === true
        ? "Want to stop Dine-in?"
        : "Want to start Dine-in?",
    dialogContent:
      isDineInActive === true
        ? "Are you sure you want to stop dine-in service? By confirming, customers will not be able to dine in at your restaurant."
        : "Are you sure you want to resume dine-in service? By confirming, customers can dine in at your restaurant again",
    backgroundColor: isDineInActive === true ? "#FFEEEE" : "#ECFFF0D4",
  };

  const confimationStatusDataForStartOrPauseDelivery = {
    isDialogOpen: true,
    dialogHeading:
      isDeliveryActive === true
        ? "Want to stop Delivery?"
        : "Want to start Delivery?",
    dialogContent:
      isDeliveryActive === true
        ? "Are you sure you want to stop delivery service? By confirming, your shop will no longer deliver orders to customers."
        : "Are you sure you want to start delivery service? By confirming, your shop will be able to deliver orders to customers.",
    backgroundColor: isDeliveryActive === true ? "#FFEEEE" : "#ECFFF0D4",
  };

  const handleClickBackIcon = () => {
    navigate("/vendor/vendorService");
  };

  const handleShopAvailabilityChange = () => {
    setIsShopDialogOpen(true);
  };

  const handleTakeawayAvailabilityChange = () => {
    setIsTakeAwayDialogOpen(true);
  };

  const handleDeliveryAvailabilityChange = () => {
    setIsDeliveryDialogOpen(true);
  };

  const handleDineinAvailabilityChange = () => {
    setIsDineinDialogOpen(true);
  };

  const changeShopStatus = async () => {
    try {
      const newShopStatus = isShopActive;
      await axiosVendorServiceInstance.put(
        `vendor-service/close-open-vendor?userId=${JSON.parse(
          localStorage.getItem("userId")
        )}&isCloseVendor=${newShopStatus}`
      );
      setIsShopActive(!isShopActive);

      if (newShopStatus === true && vendor.status !== "CLOSED") {
        vendor.status = "CLOSED";
      } else {
        vendor.status = "ACTIVE";
      }
      localStorage.setItem("user", JSON.stringify(vendor));
    } catch (err) {
      if (err.response === undefined) {
        alert("Please Try Again Later!");
      } else {
        const errmessage = err.response.data.error;
        toast.error(errmessage);
      }
    }
  };

  const changeDeliveryStatus = async () => {
    try {
      const newDeliveryStatus = !isDeliveryActive;
      await axiosVendorServiceInstance.put(
        `vendor-service/change-dispatch-modes?userId=${JSON.parse(
          localStorage.getItem("userId")
        )}&dispatchMode=DELIVERY&isEnable=${newDeliveryStatus}`
      );
      setIsDeliveryActive(!isDeliveryActive);

      if (
        newDeliveryStatus === true &&
        !vendor.dispatchModes.includes("DELIVERY")
      ) {
        vendor.dispatchModes.push("DELIVERY");
      } else {
        vendor.dispatchModes = vendor.dispatchModes.filter(
          (mode) => mode !== "DELIVERY"
        );
      }
      localStorage.setItem("user", JSON.stringify(vendor));
    } catch (err) {
      if (err.response === undefined) {
        alert("Please Try Again Later!");
      } else {
        const errmessage = err.response.data.error;
        toast.error(errmessage);
      }
    }
  };
  const changeDineInStatus = async () => {
    try {
      const newDineInStatus = !isDineInActive;
      await axiosVendorServiceInstance.put(
        `vendor-service/change-dispatch-modes?userId=${JSON.parse(
          localStorage.getItem("userId")
        )}&dispatchMode=DINE_IN&isEnable=${newDineInStatus}`
      );
      setIsDineInActive(!isDineInActive);

      if (
        newDineInStatus === true &&
        !vendor.dispatchModes.includes("DINE_IN")
      ) {
        vendor.dispatchModes.push("DINE_IN");
      } else {
        vendor.dispatchModes = vendor.dispatchModes.filter(
          (mode) => mode !== "DINE_IN"
        );
      }
      localStorage.setItem("user", JSON.stringify(vendor));
    } catch (err) {
      if (err.response === undefined) {
        alert("Please Try Again Later!");
      } else {
        const errmessage = err.response.data.error;
        toast.error(errmessage);
      }
    }
  };
  const changeTakeawayStatus = async () => {
    try {
      const newTakeawayStatus = !isTakeawayActive;
      await axiosVendorServiceInstance.put(
        `vendor-service/change-dispatch-modes?userId=${JSON.parse(
          localStorage.getItem("userId")
        )}&dispatchMode=TAKE_AWAY&isEnable=${newTakeawayStatus}`
      );
      setIsTakeawayActive(!isTakeawayActive);

      if (
        newTakeawayStatus === true &&
        !vendor.dispatchModes.includes("TAKE_AWAY")
      ) {
        vendor.dispatchModes.push("TAKE_AWAY");
      } else {
        vendor.dispatchModes = vendor.dispatchModes.filter(
          (mode) => mode !== "TAKE_AWAY"
        );
      }
      localStorage.setItem("user", JSON.stringify(vendor));
    } catch (err) {
      if (err.response === undefined) {
        alert("Please Try Again Later!");
      } else {
        const errmessage = err.response.data.error;
        toast.error(errmessage);
      }
    }
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "var(--green-600, #059669)"
              : "var(--green-600, #059669)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light"
          ? " var(--red-500, #EF4444)"
          : " var(--red-500, #EF4444)",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      <CssBaseline />
      <main>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            background: "var(--gray-50, #F9FAFB)",
          }}
        >
          {isBoth && <TopNavBarWebView index={5} />}
          <CustomVendorContainer>
            <Container
              maxWidth="sm"
              style={{
                zIndex: 10,
                padding: isBoth ? "8px 0px" : "0px",
                margin: isLargeScreen
                  ? `40px calc((100% - 1060px) / 2) 40px calc(((100% - 1060px) / 2) + 400px)`
                  : "0px auto",
                border: isBoth ? "1px solid var(--gray-200, #E5E7EB)" : "none",
                background: "#FFF",
                borderRadius: "20px",
                minHeight: "70vh",
              }}
            >
              <Container maxWidth="sm">
                <ViewStatusBlock
                  style={{
                    justifyContent: isBoth ? "space-between" : "center",
                    borderBottom: isBoth
                      ? "1px solid var(--gray-200, #E5E7EB)"
                      : "none",
                    paddingBottom: isBoth ? "16px" : "0px",
                    position: "relative",
                  }}
                >
                  {!isBoth && (
                    <ArrowBackIosIcon
                      onClick={handleClickBackIcon}
                      sx={{
                        position: "absolute",
                        top: isBoth ? 5 : 0,
                        left: -10,
                      }}
                    />
                  )}

                  <Typography
                    style={{
                      fontWeight: isBoth ? 600 : 500,
                      color: "#1C1C1C",
                      fontFamily: "Poppins",
                      fontSize: isBoth ? "28px" : "18px",
                      margin: "0px",
                      lineHeight: "130%",
                      textTransform: "capitalize",
                      marginBottom: isBoth ? 0 : "-30px",
                    }}
                  >
                    Dispatcher modes{" "}
                  </Typography>
                </ViewStatusBlock>

                <ManageShopAvailabiliyConfirmation
                  confimationStatusData={
                    confimationStatusDataForShopAvailability
                  }
                  statusChangeState={[isShopDialogOpen, setIsShopDialogOpen]}
                  changeShopStatus={changeShopStatus}
                />

                <ManageTakeAwayModeConfirmation
                  confimationStatusData={
                    confimationStatusDataForStartOrPauseTakeaway
                  }
                  statusChangeState={[
                    isTakeAwayDialogOpen,
                    setIsTakeAwayDialogOpen,
                  ]}
                  changeTakeawayStatus={changeTakeawayStatus}
                />

                <ManageDeliveryModeConfirmation
                  confimationStatusData={
                    confimationStatusDataForStartOrPauseDelivery
                  }
                  statusChangeState={[
                    isDeliveryDialogOpen,
                    setIsDeliveryDialogOpen,
                  ]}
                  changeDeliveryStatus={changeDeliveryStatus}
                />

                <ManageDineinModeConfirmation
                  confimationStatusData={
                    confimationStatusDataForStartOrPauseDinein
                  }
                  statusChangeState={[
                    isDineinDialogOpen,
                    setIsDineinDialogOpen,
                  ]}
                  changeDineInStatus={changeDineInStatus}
                />

                <VendorServicePortalFrame
                  sx={{ marginTop: isBoth ? "20px" : "70px" }}
                >
                  {!isShopActive && (
                    <Box
                      style={{
                        width: "100%",
                        padding: "8px 10px",
                        backgroundColor: "var(--red-500, #EF4444)",
                      }}
                    >
                      <Typography
                        style={{
                          color: "var(--white, var(--white, #FFF))",
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                          wordWrap: "break-word",
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        Shop is Closed
                      </Typography>
                    </Box>
                  )}
                  <Typography
                    sx={{
                      color: "var(--gray-600, #4B5563)",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      textAlign: "left",
                    }}
                  >
                    Streamline your business operations properly to provide
                    better customer service.
                  </Typography>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid var(--gray-200, #E5E7EB)",
                      padding: "16px 0px",
                    }}
                  >
                    <Grid sx={{ display: "flex", gap: "6px" }}>
                      <Grid>
                        <Box
                          sx={{
                            background:
                              isShopActive === true ? "#D1FAE5" : "#FEE2E2",
                            width: "52px",
                            height: "52px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "5px",
                            borderRadius: "6px",
                          }}
                        >
                          <Store
                            sx={{
                              color:
                                isShopActive === true ? "#065F46" : "#991B1B",
                              fontSize: "42px",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          gap: "8px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--gray-900, #111827)",
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px", // 100%
                            }}
                          >
                            Shop
                          </Typography>
                          <Button
                            align="right"
                            style={{
                              padding: "1px 12px 1px 8px",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              borderRadius: "12px",
                              color:
                                isShopActive === true ? "#065F46" : "#991B1B",
                              background:
                                isShopActive === true ? "#D1FAE5" : "#FEE2E2",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                            }}
                          >
                            <CircleIcon
                              sx={{
                                color:
                                  isShopActive === true ? "#34D399" : "#F87171",
                                fontSize: "8px",
                                marginRight: "6px",
                              }}
                            />
                            {isShopActive === true ? "ACTIVE" : "PAUSED"}
                          </Button>
                        </Grid>
                        <Grid sx={{ display: "flex", alignItems: "left" }}>
                          <Typography style={VENDOR_PORTAL_TITTLE}>
                            {isShopActive === true
                              ? "Tap to Close the Shop"
                              : "Tap to Open the Shop"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        alignItems: "center",
                      }}
                    >
                      <IOSSwitch
                        checked={isShopActive}
                        onChange={handleShopAvailabilityChange}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid var(--gray-200, #E5E7EB)",
                      paddingBottom: "16px",
                    }}
                  >
                    <Grid sx={{ display: "flex", gap: "6px" }}>
                      <Grid>
                        <Box
                          sx={{
                            background:
                              isTakeawayActive === true ? "#D1FAE5" : "#FEE2E2",
                            width: "52px",
                            height: "52px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "5px",
                            borderRadius: "6px",
                          }}
                        >
                          <Bag
                            sx={{
                              color:
                                isTakeawayActive === true
                                  ? "#065F46"
                                  : "#991B1B",
                              fontSize: "42px",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          gap: "8px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--gray-900, #111827)",
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px", // 100%
                            }}
                          >
                            {vendor.vendorCategory ===
                            VENDOR_CATEGORY.RESTAURANT
                              ? "Take Away"
                              : "Store pickup"}
                          </Typography>
                          <Button
                            align="right"
                            style={{
                              padding: "1px 12px 1px 8px",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              borderRadius: "12px",
                              color:
                                isTakeawayActive === true
                                  ? "#065F46"
                                  : "#991B1B",
                              background:
                                isTakeawayActive === true
                                  ? "#D1FAE5"
                                  : "#FEE2E2",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                            }}
                          >
                            <CircleIcon
                              sx={{
                                color:
                                  isTakeawayActive === true
                                    ? "#34D399"
                                    : "#F87171",
                                fontSize: "8px",
                                marginRight: "6px",
                              }}
                            />
                            {isTakeawayActive === true ? "ACTIVE" : "PAUSED"}
                          </Button>
                        </Grid>
                        <Grid sx={{ display: "flex", alignItems: "center" }}>
                          <Typography style={VENDOR_PORTAL_TITTLE}>
                            {isTakeawayActive === true
                              ? "Tap to pause store pickup"
                              : "Tap to Open store pickup"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        alignItems: "center",
                      }}
                    >
                      <IOSSwitch
                        checked={isTakeawayActive}
                        onChange={handleTakeawayAvailabilityChange}
                      />
                    </Grid>
                  </Grid>

                  {vendor.vendorCategory === VENDOR_CATEGORY.RESTAURANT && (
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        borderBottom: "1px solid var(--gray-200, #E5E7EB)",
                        paddingBottom: "16px",
                      }}
                    >
                      <Grid sx={{ display: "flex", gap: "6px", width: "87%" }}>
                        <Grid>
                          <Box
                            sx={{
                              background:
                                isDineInActive === true ? "#D1FAE5" : "#FEE2E2",
                              width: "52px",
                              height: "52px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "5px",
                              borderRadius: "6px",
                            }}
                          >
                            <Restaurant
                              sx={{
                                color:
                                  isDineInActive === true
                                    ? "#065F46"
                                    : "#991B1B",
                                fontSize: "42px",
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid
                          sx={{
                            display: "flex",
                            gap: "8px",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "var(--gray-900, #111827)",
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "18px", // 100%
                              }}
                            >
                              Dine-in
                            </Typography>
                            <Button
                              align="right"
                              style={{
                                padding: "1px 12px 1px 8px",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                borderRadius: "12px",
                                color:
                                  isDineInActive === true
                                    ? "#065F46"
                                    : "#991B1B",
                                background:
                                  isDineInActive === true
                                    ? "#D1FAE5"
                                    : "#FEE2E2",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                              }}
                            >
                              <CircleIcon
                                sx={{
                                  color:
                                    isDineInActive === true
                                      ? "#34D399"
                                      : "#F87171",
                                  fontSize: "8px",
                                  marginRight: "6px",
                                }}
                              />
                              {isDineInActive === true ? "ACTIVE" : "PAUSED"}
                            </Button>
                          </Grid>
                          <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              style={VENDOR_PORTAL_TITTLE}
                              sx={{ width: "100%" }}
                            >
                              {isDineInActive === true
                                ? "Tap to deactivate the Dine-in service."
                                : "Tap to activate the Dine-in service."}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "center",
                        }}
                      >
                        <IOSSwitch
                          sx={
                            {
                              // marginLeft: isBoth ? "0px" : "300px",
                              // marginTop: isBoth ? "0px" : "-65px",
                            }
                          }
                          checked={isDineInActive}
                          onChange={handleDineinAvailabilityChange}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid var(--gray-200, #E5E7EB)",
                      paddingBottom: "16px",
                    }}
                  >
                    <Grid sx={{ display: "flex", gap: "6px", width: "87%" }}>
                      <Grid>
                        <Box
                          sx={{
                            background:
                              isDeliveryActive === true ? "#D1FAE5" : "#FEE2E2",
                            width: "52px",
                            height: "52px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "5px",
                            borderRadius: "6px",
                          }}
                        >
                          <Delivery
                            sx={{
                              color:
                                isDeliveryActive === true
                                  ? "#065F46"
                                  : "#991B1B",
                              fontSize: "42px",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          gap: "8px",
                          flexDirection: "column",
                          justifyContent: "left",
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--gray-900, #111827)",
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "18px", // 100%
                              textAlign: "left",
                            }}
                          >
                            Delivery
                          </Typography>
                          <Button
                            align="right"
                            style={{
                              padding: "1px 12px 1px 8px",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              borderRadius: "12px",
                              color:
                                isDeliveryActive === true
                                  ? "#065F46"
                                  : "#991B1B",
                              background:
                                isDeliveryActive === true
                                  ? "#D1FAE5"
                                  : "#FEE2E2",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                            }}
                          >
                            <CircleIcon
                              sx={{
                                color:
                                  isDeliveryActive === true
                                    ? "#34D399"
                                    : "#F87171",
                                fontSize: "8px",
                                marginRight: "6px",
                              }}
                            />
                            {isDeliveryActive === true ? "ACTIVE" : "PAUSED"}
                          </Button>
                        </Grid>
                        <Grid sx={{ display: "flex", alignItems: "center" }}>
                          <Typography style={VENDOR_PORTAL_TITTLE}>
                            {isDeliveryActive === true
                              ? "Tap to pause the Delivery service."
                              : "Tap to activate the Delivery service."}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        alignItems: "center",
                      }}
                    >
                      <IOSSwitch
                        checked={isDeliveryActive}
                        onChange={handleDeliveryAvailabilityChange}
                      />
                    </Grid>
                  </Grid>
                </VendorServicePortalFrame>
              </Container>
              {/* {!isBoth && <VendorBottomNavbar clickedIndex={2} />} */}
            </Container>
          </CustomVendorContainer>
        </Box>
      </main>
    </>
  );
};
