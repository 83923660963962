import Link from "@mui/material/Link";

export const MONTHLY_SUBSCRIPTION_FEE = 9.99;
export const ANNUAL_SUBSCRIPTION_FEE = 90;
export const RESTRICTED_MONTHLY_ORDER_LIMIT = 50;
export const RESTRICTED_MENU_COUNT = 20;
export const RESTRICTED_OFFER_COUNT = 1;
export const COUNTRY_CODE = { 45: "DK", 94: "LK" };
export const ITEM_KIND = { UNIT: "UNIT", VARIANT_OPTION: "VARIANT_OPTION" };
export const MEASUREMENT_UNIT = { G: "g", KG: "kg", L: "l", ML: "ml" };
export const VENDOR_CATEGORY = {
  RESTAURANT: "RESTAURANT",
  GROCERY: "GROCERY",
  RETAIL: "RETAIL",
};
export const UNIT_OF_SELLING = [
  "g(Gram)",
  "kg(Kilogram)",
  "L(Liter)",
  "ml(Milliliter)",
];
export const UNIT_OF_SELLING_MAPPED = {
  G: "g(Gram)",
  KG: "kg(Kilogram)",
  L: "L(Liter)",
  ML: "ml(Milliliter)",
};
export const CURRENCY_CODES = {
  LKR: ["Sri Lankan rupee", "RS"],
  DKK: ["Danish krone", "kr"],
  USD: ["United States dollar", "$"],
  EUR: ["Euro", "€"],
  CAD: ["Canadian dollar", "$"],
  GBP: ["Pound sterling", "£"],
  INR: ["Indian Rupee", "Rs"],
  CHF: ["Swiss franc", "CHF"],
};

export const availableCountries = [
  "us",
  "ca",
  "gb",
  "dk",
  "fr",
  "lk",
  "nl",
  "in",
  "ch",
];

export const alteredGender = {
  MALE: "Men",
  FEMALE: "Women",
  UNISEX: "Unisex",
};

export const retry = {
  FIELD_ATTEMPT: "attempt",
  RETRY_COUNT: "3",
  RETRY_LIMIT_EXCEEDED_MESSAGE:
    "You have exceeded the maximum number of retry attempts!",
};

export const digitalPayment = {
  PAYHERE: "PayHere",
  PAYPAL: "Paypal",
  STRIPE: "Stripe",
};

export const dpProviders = [
  { name: "PayHere", isAvailable: true },
  { name: "Paypal", isAvailable: false },
  { name: "Stripe", isAvailable: false },
];

export const pgProviderInfo = {
  PAYHERE: (
    <p>
      Access your Merchant ID and Merchant Secret from&nbsp;
      <Link
        href="https://payhere.lk/"
        style={{
          color: "var(--gray-600, #4B5563)",
          textDecorationLine: "underline",
        }}
      >
        Your PayHere Dashboard
      </Link>
      . Navigate to Integrations, add a new domain "feasto.io" and generate the
      Merchant secret key.
    </p>
  ),
  PAYPAL: "",
  TRIPE: "",
};
