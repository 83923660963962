import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosVendorServiceInstance } from "../../../../services/api";
import { toast } from "react-toastify";

export const DeliveryRestrictionTime = () => {
  const isLargeScreen = useMediaQuery("(min-width:1349px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:710px) and (max-width:1349px)"
  );

  const isBoth = isLargeScreen || isMediumScreen;
  const location = useLocation();
  const navigate = useNavigate();
  const [bufferTime, setBufferTime] = useState(
    dayjs(`2022-04-17T${JSON.parse(localStorage.getItem("user")).bufferTime}`)
  );

  const handleConfirm = async () => {
    if (bufferTime) {
      const formattedTime = bufferTime.format("HH:mm");

      try {
        const response = await axiosVendorServiceInstance.post(
          `/vendor-service/update-delivery-restriction-time?userId=${JSON.parse(
            localStorage.getItem("userId")
          )}&bufferTime=${formattedTime}`
        );
        const vendor = JSON.parse(localStorage.getItem("user"));
        vendor.bufferTime = response.data.data.bufferTime;
        localStorage.setItem("user", JSON.stringify(vendor));

        navigate("/vendor/vendorUpdatingDeliveryRestrictionTimeSuccess");
        setTimeout(() => {
          if (isBoth) {
            navigate("/vendor/vendorDeliveryConfigurations", {
              state: { selectedTab: 1 },
            });
          } else {
            navigate("/vendor/vendorService");
          }
        }, 1500);
      } catch (err) {
        if (err.response === undefined) {
          alert("Please Try Again Later!");
        } else {
          const errmessage = err.response.data.error;
          toast.error(errmessage);
        }
      }
    } else {
      toast.warn("No buffer time selected");
    }
  };

  const handleClickBackIcon = () => {
    navigate("/vendor/vendorDeliveryConfigurations", {
      state: location.state,
    });
  };

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          background: "#FFF",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            padding: isBoth ? "0px" : "12px 0px",
            zIndex: "10",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: isBoth ? "space-between" : "center",
                alignItems: "center",
                position: "relative",
                marginBottom: "20px",
              }}
            >
              {!isBoth && (
                <IconButton
                  onClick={handleClickBackIcon}
                  color="inherit"
                  sx={{
                    position: "absolute",
                    top: isBoth ? 10 : -10,
                    left: 0,
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              )}

              <Typography
                sx={{
                  fontWeight: 600,
                  color: "#1C1C1C",
                  fontFamily: "Poppins",
                  fontSize: isBoth ? "20px" : "18px",
                  lineHeight: "130%",
                  textTransform: "capitalize",
                }}
              >
                Delivery Restriction Time
              </Typography>
            </Box>

            <Typography
              variant="body1"
              sx={{
                color: "var(--gray-600, #4B5563)",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 400,
                marginBottom: "10px",
                textAlign: "left",
                padding: isLargeScreen ? "0px" : "10px",
              }}
            >
              Customers can't place an order for a particular delivery schedule
              once the restriction time has passed.
            </Typography>

            <Typography
              sx={{
                color: "#374151",
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: "500",
                marginBottom: "-6px",
                textAlign: "left",
                padding: isLargeScreen ? "0px" : "10px",
              }}
            >
              Set restriction time
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                sx={{
                  width: "100%",
                  padding: isLargeScreen ? "0px" : "10px",
                  marginTop: isLargeScreen ? "10px" : "0px",
                }}
                views={["hours", "minutes"]}
                value={bufferTime}
                onChange={(newValue) => setBufferTime(newValue)}
                format="HH:mm"
                ampm={false}
              />
            </LocalizationProvider>
          </Box>

          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "auto",
              marginBottom: "20px",
              paddingLeft: isBoth ? "0px" : "16px",
              paddingRight: isBoth ? "0px" : "16px",
            }}
          >
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleConfirm}
                sx={{
                  borderRadius: "6px",
                  background: "var(--green-600, #059669)",
                  color: "#FFF",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                  padding: "12px 13px",
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
