import {
  Button,
  CardActionArea,
  Grid,
  TextField,
  Typography,
  createTheme,
  CardMedia,
  Container,
  TableCell,
  TableRow,
  IconButton,
  Box,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { styled } from "@mui/system";

export const CustomVendorContainer = styled("div")({
  backgroundColor: "#FFF",
  display: "flex",
  overflow: "hidden",
  "@media (min-width: 700px)": {
    background: "var(--gray-50, #F9FAFB)",
  },
  minHeight: "95vh",
});

export const VendorSuccessImage = styled("img")({
  flexShrink: 0,
  width: "312px",
  height: "294px",
  marginTop: "25%",
});

export const SuccessTextSymbolGrid = styled(Grid)({
  flexShrink: 0,
  width: "306px",
  height: "154px",
  marginTop: "13%",
  marginBottom: "13%",
  paddingLeft: "3px",
});

export const SuccessMainTypography = styled(Typography)({
  color: "#1C1C1C",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "120%",
});

export const OrderCompletedMainTypography = styled(Typography)({
  color: "#263238",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "18px",
  width: "350px",
});

export const SuccessSubTypography = styled(Typography)({
  // width: "224px",
  color: "#6B7280",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "160%",
  marginTop: "12px",
});

export const VendorSuccessSymbol = styled("img")({
  flexShrink: 0,
  width: "63px",
  height: "63px",
  marginTop: "25px",
});

export const AllMainTypography = styled(Typography)({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "130%" /* 31.2px */,
  textTransform: "capitalize",
  marginTop: "3%",
  textAlign: "center",
});

export const AllSearchBar = styled(TextField)({
  flexShrink: 0,
  width: "310px",
  borderRadius: "8px",
  background: "#FCF0F0",
  marginLeft: "20px",
  marginTop: "24px",

  "& input::placeholder": {
    color: "#000000",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "130%" /* 18.2px */,
    textTransform: "capitalize",
  },
});

export const MenusSearchBar = styled(TextField)({
  flexShrink: 0,
  width: "310px",
  borderRadius: "8px",
  marginTop: "10px",

  "& input::placeholder": {
    color: "#000000",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "130%" /* 18.2px */,
    textTransform: "capitalize",
  },
});

export const AllOffersSubTypography = styled(Typography)({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "130%" /* 23.4px */,
  textTransform: "capitalize",
  // marginLeft: "20px",
  marginTop: "15px",
  marginBottom: "15px",
  textAlign: "left",
});

export const VendorCardActionArea = styled(CardActionArea)({
  width: "364px",
  height: "146px",
  borderRadius: "8px",

  background: "#FFFFFF",
  boxShadow: "0px 0px 6px 0px rgba(219, 219, 219, 0.42)",
});

export const ViewStatusBlock = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "13px",
  marginBottom: "18px",
});

export const OfferImageBlock = styled("div")({
  marginTop: "22px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "24px",
});

export const VendorConfirmButton = styled(Button)(({ theme, disabled }) => ({
  width: "100%",
  marginTop: "10%",
  marginBottom: "80px",
  paddingTop: "15px",
  paddingBottom: "15px",
  paddingLeft: "30px",
  paddingRight: "30px",
  borderRadius: "8px",
  backgroundColor: disabled ? "#808080" : "#111827",
  "@media (max-width:410px)": {
    fontSize: "14px",
  },

  color: disabled ? "#000000" : "#FFFFFF",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px",
  textTransform: "none",

  "&:hover": {
    backgroundColor: "#EF4444",
  },
  "&:disabled": {
    opacity: 0.99,
    cursor: "not-allowed",
  },
}));

export const VendorCancelButton = styled(Button)({
  width: "100%",
  marginTop: "10%",
  marginBottom: "80px",
  paddingTop: "15px",
  paddingBottom: "15px",
  paddingLeft: "30px",
  paddingRight: "30px",
  borderRadius: "8px",
  backgroundColor: "#000",
  "&:hover": {
    backgroundColor: "#000",
  },
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px",
  textTransform: "none",
});

export const VendorEditButton = styled(Button)(({ theme, disabled }) => ({
  marginTop: "10%",
  width: "100%",
  marginBottom: "80px",
  paddingTop: "15px",
  paddingBottom: "15px",
  paddingLeft: "30px",
  paddingRight: "30px",
  borderRadius: "8px",
  border: "1px #CDCDCD solid",
  backgroundColor: disabled ? "#000000" : "#111827",

  color: disabled ? "#808080" : "#FFFFFF",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px", // 112.5%
  textTransform: "none",

  "&:disabled": {
    opacity: 0.99, // You can adjust the opacity for better visual indication
    cursor: "not-allowed",
  },
}));

export const VendorCancelButton1 = styled(Button)({
  width: "45%",
  marginTop: "10%",
  marginBottom: "80px",
  paddingTop: "15px",
  paddingBottom: "15px",
  paddingLeft: "30px",
  paddingRight: "30px",
  borderRadius: "8px",
  backgroundColor: "#000",
  "@media (max-width:410px)": {
    fontSize: "14px",
  },

  color: "#FFF",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px", // 112.5%
  textTransform: "none",

  "&:hover": {
    backgroundColor: "#000",
  },
});

export const ShopClosedOkayButton = styled(Button)({
  width: "127px",
  marginTop: "10%",
  marginBottom: "5%",
  paddingTop: "15px",
  paddingBottom: "15px",
  paddingLeft: "30px",
  paddingRight: "30px",
  borderRadius: "8px",
  backgroundColor: "#FF0000",

  color: "#FFFFFF",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px", // 112.5%
  textTransform: "none",

  "&:hover": {
    backgroundColor: "#808080",
  },
});

export const CreateOfferSubTypography = styled(Typography)({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "130%" /* 23.4px */,
  textTransform: "capitalize",
  display: "flex",
  marginTop: "8%",
  marginBottom: "43px",
  justifyContent: "center",
});

export const VendorSubTypography = styled(Typography)({
  color: "var(--black, #000)",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "130%" /* 23.4px */,
  textTransform: "capitalize",
  // display: "flex",
  marginTop: "10px",
  // marginBottom: "43px",
  justifyContent: "center",
});

export const BackButtonIcon = styled(ArrowBackIosIcon)({
  maxWidth: "sm",
  color: "#000000",
  width: "27px",
  height: "24px",
  position: "absolute",
  left: "20px",
  cursor: "pointer",
});

export const ImageTextField = styled(TextField)({
  "& input[type=file]::file-selector-button": {
    display: "none",
    height: "104px",
  },
  "& input[type=file]": {
    color: "transparent",
  },
  height: "104px",
  cursor: "pointer",

  maxWidth: "100%",
  borderRadius: "9px",
  border: "1px solid #CAC1C1",
  marginLeft: "5px",
  marginRight: "10px",
  marginBottom: "10px",
  display: "flex",
  justifyContent: "center",
});

export const VendorOfferItemDiv = styled("div")({
  width: "358px",
  height: "59px",
  flexShrink: 0,
});

export const ItemImageBlock = styled("div")({
  marginTop: "22px",
  marginLeft: "20px",
  marginBottom: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid #CAC1C1",
  width: "127px",
  height: "148px",
  borderRadius: "8px",
});

export const SelectPortionTypography = styled(Typography)({
  color: "#263238",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "18px" /* 112.5% */,
  display: "flex",
  justifyContent: "flex-start",
  marginTop: "24px",
  marginLeft: "20px",
});

export const AllOrdersTopNavbarTypography = styled(Typography)({
  fontFamily: "Poppins", // Sets font family to Poppins
  fontSize: "14px", // Sets font size to 16 pixels
  fontStyle: "normal", // Sets font style to normal
  fontWeight: 500, // Sets font weight to 400 (normal)
  lineHeight: "20.8px", // Sets line height to 20.8 pixels
  display: "flex", // Sets display property to flex
  justifyContent: "flex-start",
  color: "#000", // Aligns content to the start of the flex container horizontally
});

export const OrderCardActionArea = styled(CardActionArea)({
  borderRadius: "8px",
  marginTop: "5px",
  marginBottom: "5px",
  boxShadow: "0px 0px 6px 0px rgba(219, 219, 219, 0.42)",
});

export const OrderCard = styled(Container)({
  display: "flex",
  width: "100%",
  paddingRight: "5px",
  paddingLeft: "5px",
  boxShadow: "box-shadow: 0px 0px 6px 0px rgba(219, 219, 219, 0.42)",
});

export const OrderCardMedia = styled(CardMedia)({
  width: "50px",
  height: "50px",
  flexShrink: 0,
  borderRadius: "8px",
});

export const OrderCardItemTableCell = styled(TableCell)(({ flex }) => ({
  flex: flex,
  border: "none",
  padding: "2px",
}));

export const OrderCardItemTableRow = styled(TableRow)(({ borderBottom }) => ({
  border: "none",
  margin: "0",
  padding: "0",
  borderBottom: borderBottom,
}));

export const DispatchModeAlteredContainer = styled(Container)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "140px",
  height: "40px",
  marginTop: "5px",
  borderRadius: "100px",
});
export const OrderStatusContainer = styled(Container)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "140px",
  height: "40px",
  marginTop: "5px",
  borderRadius: "100px",
});

export const OrderStatusTypography = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  textTransform: "capitalize",
  paddingRight: "0px",
  color: "var(--gray-900, #111827)",
});

export const OrderCardTitleTypography = styled(Typography)({
  color: "var(--gray-900, #111827)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textAlign: "left",
  marginBottom: "4px",
});

export const OrderedItemDetailTypography = styled(Typography)({
  color: "var(--gray-900, #111827)",
  textAlign: "left",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "130%",
  textTransform: "capitalize",
  margin: " 4px 0px",
});

export const OrderStatusTitle = styled(Typography)({
  color: "var(--gray-900, #111827)",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "130%",
  textTransform: "capitalize",
});

export const OrderStatusTitleSideNavBar = styled(Typography)({
  color: "var(--gray-900, #111827)",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "130%",
  textTransform: "capitalize",
});

export const OrderStatusSubTitleSideNavBar = styled(Typography)({
  color: "var(--gray-700, #374151)",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "135%",
  textTransform: "capitalize",
  padding: "10px 0px 10px 16px",
});

export const ViewAllTypography = styled(Typography)({
  color: "var(--gray-700, #374151)",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "114%",
  textDecoration: "none",
  background: "var(--white, #FFF)",
  textTransform: "capitalize",
  cursor: "pointer",
  display: "flex",
  width: "91%",
  margin: "0 auto", // Center the button horizontally
  padding: "13px 9px", // Add paddingborder: 1px solid #E5E5E5
  borderRadius: "6px", // Add border radius
  border: "1px dashed var(--gray-300, #D1D5DB)",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "15px",
  marginBottom: "10px",
  boxShadow: "none",
});
export const TotalOrdersCountContainer = styled(Container)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "20px",
  marginTop: "20px",
  marginLeft: "13px",
  marginRight: "20px",
  padding: "12px",
});

export const TotalOrdersTypography = styled(Typography)({
  color: "var(--Accent-red, #E52D3C)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "130%",
  margin: 0,
});
export const TotalOrdersContainer = styled(Container)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end", // Align children to the end of the row
  width: "auto", // Allow the container to adjust its width based on content
  height: "30px",
  borderRadius: "8px",
  background: "#d9d9d9",
  padding: "10px 20px 20px 10px", // Add padding for spacing around the count
});

export const ShowingOrdersTypography = styled(Typography)({
  color: "var(--black, #000)",
  fontFamily: "Poppins",
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: 400,
});

export const OrderPlacedDateTimeTypography = styled(Typography)({
  color: "var(--black, #000)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textAlign: "left",
  marginBottom: "10px",
});

export const StyledCircle = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  background: "#E52D3C",
  color: "#ffff",
  marginLeft: "8px",
});

export const StyledCircleForDateTime = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  background: "black",
  marginRight: "8px",
});

export const ViewOrdersButton = styled(Button)({
  marginTop: "10px",
  display: "inline-flex", // Change to inline-flex to place buttons in a row
  maxWidth: "45%", // Set maximum width for responsiveness
  padding: "19px 26px", // Adjust padding as needed
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  margin: "5px", // Add a small margin between buttons
});

export const ViewOrdersTypography = styled(Typography)({
  dispaly: "flex",
  color: "var(--black, #000)",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "130%",
  textTransform: "capitalize",
});

export const LoadMoreOrdersTypography = styled(Typography)({
  textTransform: "initial",
  color: "var(--gray-700, #374151)",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "130%",
  display: "flex",
  alignItems: "center",
});

export const LoadMoreOrdersButton = styled(Button)({
  borderRadius: "10px",
  width: "100%",
  border: "1px solid var(--gray-300, #D1D5DB)",
  padding: "16px 13px",
  marginBottom: "10px",
  marginTop: "10px",
  color: "#0000",
  background: "#FFFF",
  "&:hover": {
    background: "#FFFF",
  },
});

export const LoadMoreOrdersContainer = styled(Container)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "10px",
  marginBottom: "20px",
});

export const DispatchModeButton = styled(Button)({
  display: "flex",
  height: "57px",
  flexShrink: "0",
  borderRadius: "12px",
  background: "#FCF0F0",
  width: "32%",
  padding: "13px 6px",
});

export const DispatchModeContainer = styled(Container)({
  display: "flex",
  justifyContent: "space-between",
  padding: "13px 0px",
  width: "100%",
});

export const DispatchModeTypography = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "130%" /* 15.6px */,
  textTransform: "capitalize",
});

export const TopNavbarButton = styled(Button)(() => ({
  textTransform: "capitalize",
  width: "100%",
  borderRadius: "0px",
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
}));

export const TopNavbarContainer = styled(Container)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "10px",
  "@media (max-width:992px)": {
    borderBottom: "1px solid #C9BDBD",
  },
  paddingLeft: "0px",
  paddingRight: "0px",
});

export const AllOrdersSearchBar = styled(TextField)({
  borderColor: "#6C6C6C",
  borderRadius: "8px",
  height: "46px",
  marginTop: "10px",
  paddingBottom: "10px",
  width: "100%",
  marginRight: "10px",
  marginLeft: "10px",

  "& input::placeholder": {
    color: "var(--light-grey, #6C6C6C)",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "130%" /* 18.2px */,
    textTransform: "capitalize",
  },
});

export const AllItemsSearchBar = styled(TextField)({
  borderRadius: "8px",
  height: "48px",
  width: "100%",
  padding: "0px 20px",

  "& input::placeholder": {
    color: "var(--light-grey, #6C6C6C)",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "130%" /* 18.2px */,
    textTransform: "capitalize",
  },
});

export const VendorServiceFrame = styled("div")({
  display: "flex",
  width: "377px",
  height: "256.002px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",

  borderRadius: "16px",
  border: "1px solid #A9A9A9",
});

export const VendorUpdateTimeButton = styled(Button)({
  marginBottom: "10px",
  paddingTop: "10px",
  paddingBottom: "10px",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.7)", // 30% transparent black on hover
  },
});

export const VendorServiceTypography = styled(Typography)({
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px" /* 128.571% */,
  textTransform: "capitalize",
});

export const ServiceTimeTable = styled(Typography)({
  display: "flex",
  width: "377px",
  height: "256.002px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  borderRadius: "16px",
  border: "1px solid #A9A9A9",
});

export const VendorServicePortalFrame = styled("div")({
  display: "flex",
  // width: "374px",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  gap: "20px",
  flexWrap: "wrap",
  marginBottom: "50px",
  marginTop: "70px",
  paddingRight: "0px",
  paddingLeft: "0px",
});

export const DispatchModeStatusTypography = styled("Typography")({
  color: "var(--black, #000)",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "8px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "18px",
});

export const VendorServicePortal = styled("div")({
  // display: "flex",
  width: "calc(50% - 12px)",
  // width: "195px",
  height: "195px",
  flexDirection: "column",
  alignItems: "center",
  // textAlign: "center",
  cursor: "pointer",
  flexShrink: 0,
  borderRadius: "8px",
  // border: "1px solid #BDBDBD",
});

export const DeliveryAreaFeeInputTextField = styled(TextField)({
  width: "100%",
  padding: "10px 0px",
  height: "44px",
  borderRadius: "8px",
  fontFamily: "Poppins",
  // fontSize: "50px",
  // boxShadow: "0px 4px 8px 0px rgba(247, 0, 0, 0.10)",
  color:
    "linear-gradient(0deg, #BDBDBD, #BDBDBD), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
  "& input::placeholder": {
    color: "#808080",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "130%",
    textTransform: "capitalize",
    alignItems: "center",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: "Poppins",
    color: "#808080",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "25px",
    paddingRight: "25px",
    // letterSpacing: "10px",
    textAlign: "center",
  },
  "&:hover .MuiOutlinedInput-input": {
    borderColor: "lightgrey",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgrey",
  },
});

export const VendorUpdateTimeHeader = styled("div")({
  display: "flex",
  // flexDirection: 'row', // Horizontal arrangement
  alignContent: "",
  justifyContent: "space-between",
});

export const VendorUpdateTimeHeading = styled("div")({
  position: "absolute",
  // left: '112px',
  top: "40px",
  alignContent: "space-around",
});

export const VendorUpdateTimeSelectionForm = styled("div")({
  display: "flex",
  flexDirection: "row",
  padding: "16px",
  alignItems: "center",
  justifyContent: "space-between",
});

export const VendorProfileBottomButtonFrame = styled("div")({
  display: "flex",
  flexDirection: "row",
  // width: '388px',
  // padding: '16px',
  gap: "16px",
  // flexShrink: '0',
  alignItems: "center",

  // justifyContent: "space-between",
  marginTop: "96px",
  marginBottom: "70px",
});

export const VendorProfileBottomButtons = styled(Button)({
  height: "44px",
  alignSelf: "stretch",
  borderRadius: "8px",
  border: "1px solid var(--lighter-grey, #BDBDBD)",
  padding: "10px",
  width: "50%",
  marginBottom: "16px",
  cursor: "pointer",
});

export const VendorDialogBoxButton = styled(Button)({
  // width: "127px",
  marginTop: "10%",
  marginBottom: "5%",
  paddingTop: "15px",
  paddingBottom: "15px",
  paddingLeft: "30px",
  paddingRight: "30px",
  borderRadius: "8px",
  // backgroundColor: "#FFFFFF",

  // color: "#000000",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px", // 112.5%
  textTransform: "none",
});

export const ProfilePhotoContainer = styled("div")({
  justifyContent: "center",
  display: "flex",
  overflow: "hidden",
  marginBottom: "10px",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: "0px",
  paddingRight: "0px",
});

// Styles for UpdateOrderStatus screen

export const FixedHeaderContainer = styled(Container)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  position: "sticky",
  top: "0",
  right: 0,
  left: 0,
  zIndex: 1002,
  backgroundColor: "white",
});

export const BackIconButton = styled(IconButton)({
  position: "absolute",
  top: 0,
  left: 40,
  zIndex: 1001,
});

export const OrderStatusBox = styled(Box)(({ background }) => ({
  width: "100%",
  height: "34px",
  flexShrink: 0,
  background: background,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const OrderStatusBoxTypo = styled(Typography)(({ color }) => ({
  color: color,
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
}));
export const ItemDetailsHeader = styled(Typography)({
  color: "var(--gray-900, #111827)",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "18px",
  textAlign: "left",
});

export const OfferNameTypo = styled(Typography)({
  color: "var(--gray-500, #6B7280)",
  textAlign: "right",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "12px",
});

export const AcceptCancelButton = styled(Button)({
  borderRadius: "8px",
  border: "1px solid var(--white, #FFF)",
  background: "var(--white, #FFF)",
  boxShadow: "0px 0px 10px 1px rgba(181, 181, 181, 0.36)",
  display: "flex",
  width: "48%",
  padding: "15px 0px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
});

export const AcceptCancelTypography = styled(Typography)({
  color: "var(--black, #000)",
  fontWeight: 400,
  lineHeight: "18px",
  textTransform: "capitalize",
});
export const OrderLabelTypo = styled(Typography)({
  color: "#686868",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
});

export const OrderTextTypo = styled(Typography)({
  color: "#263238",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
  textTransform: "capitalize",
  textAlign: "right",
});

export const OrderStatusButtonContainer = styled(Container)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "12px",
  marginRight: "0px",
  marginLeft: "0px",
  paddingLeft: "0px",
  paddingRight: "0px",
});

export const OrderStatusButton = styled(Container)({
  display: "flex",
  width: "100%",
  padding: "15px 0px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  borderRadius: "8px",
  background: "var(--black, #000)",
});
export const ItemDetailTypography = styled(Typography)({
  color: "var(--black, #000)",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
});

export const ItemPriceTypography = styled(Typography)({
  color: "var(--black, #000)",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "12px",
});

// Styles for VendorRevenueSummary screen

export const VendorRevenueSummaryCardContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "10px",
  marginBottom: "10px",
  borderRadius: "8px",
  border: "1px solid #D6D6D6",
  boxShadow: "0px 0px 60px 0px rgba(219, 219, 219, 0.42)",
});

export const RequestButton = styled(Button)({
  width: "100px",
  maxHeight: "56px",
  borderRadius: "8px",
  backgroundColor: "#232323",
  color: "#FFFFFF",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "100",
  lineHeight: "18px", // 112.5%
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.7)", // 30% transparent black on hover
  },
});

export const VendorUploadProfileButton = styled(Button)({
  color: "#000000",
  backgroundColor: "#BDBDBD",
  height: "44px",
  alignSelf: "stretch",
  borderRadius: "8px",
  border: "1px solid var(--lighter-grey, #BDBDBD)",
  padding: "10px",
  // marginRight: "20px",
  marginBottom: "16px",
  cursor: "pointer",
  fontSize: "12px",
  fontWeight: "bold",
});

export const VendorServiceImgBox = styled(Box)({
  width: "92px",
  height: "92px",
  border: "1px solid #F2F2F2",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  cursor: "pointer",
});

export const ReceiptItemContainer = styled(Container)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 10px",
});

export const ReceiptItemLabel = styled(Typography)({
  color: "var(--black, #000)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "130%",
  textTransform: "capitalize",
});

export const ReceiptItemValue = styled(Typography)({
  color: "#676767",
  textAlign: "right",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "130%",
  textTransform: "capitalize",
  // whiteSpace: "nowrap",
});

export const ReceiptItemLabelContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0px",
});

export const UpgradeButton = styled(Button)({
  // width: "127px",
  // marginTop: "10%",
  // marginBottom: "5%",
  paddingTop: "8px",
  paddingBottom: "8px",
  paddingLeft: "10px",
  paddingRight: "10px",
  borderRadius: "8px",
  // border:"",
  backgroundColor: "#E52D3C",

  color: "#FFFFFF",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px", // 112.5%
  textTransform: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(229, 45, 60, 0.9)",
  },
});
export const VendorShortCutEditDeleteActivateButton = styled(Button)({
  width: "100%",
  padding: "17px",
  gap: "12px",
  borderRadius: "6px",
  color: "#000000",
  textAlign: "left",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px", // 112.5%
  textTransform: "none",
  display: "flex",
  flexDirection: "column",

  "&:hover": {
    backgroundColor: "none",
  },
});

export const VendorConfirmButton1 = styled(Button)(({ theme, disabled }) => ({
  width: "45%",
  marginTop: "10%",
  marginBottom: "80px",
  paddingTop: "15px",
  paddingBottom: "15px",
  paddingLeft: "30px",
  paddingRight: "30px",
  borderRadius: "8px",
  backgroundColor: disabled ? "#808080" : "#EF4444",
  "@media (max-width:410px)": {
    fontSize: "14px",
  },

  color: "#FFF",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px", // 112.5%
  textTransform: "none",

  "&:hover": {
    backgroundColor: disabled ? "#808080" : "#EF4444",
  },
}));

export const CustomBoxForWebView = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "0px",
  background: "var(--gray-50, #F9FAFB)",
});
