import { createSlice } from "@reduxjs/toolkit";

const posCartSlice = createSlice({
  name: "posCart",
  initialState: { value: [] },
  reducers: {
    addToCart: (state, action) => {
      state.value = action.payload;
    },
    clearCart: (state) => {
      state.value = [];
    },
  },
});

const allOffersSlice = createSlice({
  name: "allOffers",
  initialState: { value: [] },
  reducers: {
    setAllOffers: (state, action) => {
      state.value = action.payload;
    },
  },
});

const itemIdsAndApplicableOfferIdsSlice = createSlice({
  name: "itemIdsAndApplicableOfferIds",
  initialState: { value: {} },
  reducers: {
    setItemIdsAndApplicableOfferIds: (state, action) => {
      state.value = action.payload;
    },
  },
});

const posChargesSlice = createSlice({
  name: "posCharges",
  initialState: { value: [] },
  reducers: {
    setPosCharges: (state, action) => {
      state.value = action.payload;
    },
  },
});

const posChargeIdsSlice = createSlice({
  name: "posChargeIds",
  initialState: { value: [] },
  reducers: {
    setPosChargeIds: (state, action) => {
      state.value = action.payload;
    },
  },
});

const selectedOfferSlice = createSlice({
  name: "selectedOffer",
  initialState: { value: null },
  reducers: {
    setSelectedOffer: (state, action) => {
      state.value = action.payload;
    },
  },
});

const customerNameSlice = createSlice({
  name: "customerName",
  initialState: { value: null },
  reducers: {
    setCustomerName: (state, action) => {
      state.value = action.payload;
    },
  },
});

const customerContactNumberSlice = createSlice({
  name: "customerContactNumber",
  initialState: { value: null },
  reducers: {
    setCustomerContactNumber: (state, action) => {
      state.value = action.payload;
    },
  },
});

const discountSlice = createSlice({
  name: "discount",
  initialState: 0,
  reducers: {
    setDiscount: (state, action) => {
      return action.payload;
    },
    clearDiscount: () => 0,
  },
});

const totalPosChargeSlice = createSlice({
  name: "totalPOSCharge",
  initialState: 0,
  reducers: {
    setTotalPosCharge: (state, action) => {
      return action.payload;
    },
    clearTotalPosCharge: () => 0,
  },
});

// Export actions from both slices
export const { addToCart, clearCart } = posCartSlice.actions;
export const { setAllOffers } = allOffersSlice.actions;
export const { setPosCharges } = posChargesSlice.actions;
export const { setItemIdsAndApplicableOfferIds } =
  itemIdsAndApplicableOfferIdsSlice.actions;
export const { setPosChargeIds } = posChargeIdsSlice.actions;
export const { setSelectedOffer } = selectedOfferSlice.actions;
export const { setCustomerName } = customerNameSlice.actions;
export const { setCustomerContactNumber } = customerContactNumberSlice.actions;
export const { setDiscount, clearDiscount } = discountSlice.actions;
export const { setTotalPosCharge, clearTotalPosCharge } =
  totalPosChargeSlice.actions;

// Combine reducers if necessary (typically done in store setup)
export default {
  posCart: posCartSlice.reducer,
  allOffers: allOffersSlice.reducer,
  posCharges: posChargesSlice.reducer,
  itemIdsAndApplicableOfferIds: itemIdsAndApplicableOfferIdsSlice.reducer,
  selectedOffer: selectedOfferSlice.reducer,
  posChargeIds: posChargeIdsSlice.reducer,
  customerName: customerNameSlice.reducer,
  customerContactNumber: customerContactNumberSlice.reducer,
  discount: discountSlice.reducer,
  totalPOSCharge: totalPosChargeSlice.reducer,
};
