import { createTheme } from "@mui/material/styles";
import { useState } from "react";

export const useThemeConfig = () => {
  const vendorData = JSON.parse(localStorage.getItem("vendor"));
const userData = JSON.parse(localStorage.getItem("user"));
// JSON.parse(localStorage.getItem("user"))["themeColour"]

const theme1 = vendorData?.themeColour ?? null;
const theme2 = userData?.themeColour ?? null;
const finalTheme = theme1 !== null ? theme1 : theme2 !== null ? theme2 : "#FF0000";

  const [globalColor, setGlobalColor] = useState(finalTheme);
  const [textColour, setTextColour] = useState("#FFFFFF");

  const handleColorChange = (color) => {
    setGlobalColor(color);
    const isColorDark = isDarkColor(color);
    setTextColour(isColorDark ? "#FFFFFF" : "#000000");
  };

  const isDarkColor = (color) => {
    let r = parseInt(color.substring(1, 3), 16);
    let g = parseInt(color.substring(3, 5), 16);
    let b = parseInt(color.substring(5, 7), 16);
    let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance < 0.5;
  };

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: globalColor,
            color: textColour,
            "&:hover": {
              backgroundColor: globalColor,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: globalColor,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              color: globalColor,
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            "& .Mui-completed": {
              color: globalColor,
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            color: textColour,
            backgroundColor: globalColor,
          },
        },
      },
    },
  });

  return { theme, globalColor, textColour, handleColorChange };
};
