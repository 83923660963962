import { createContext, useState } from "react";

export const UserContext = createContext();

function UserProvider(props) {
  const [user, setUser] = useState(null);

  const updateUser = (newUser) => {
    localStorage.setItem("user", JSON.stringify(newUser));
    setUser(newUser);
  };
  const [contactNumber, setContactNumber] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userId, setUserId] = useState(null);
  const [authMode, setAuthMode] = useState(null);

  const updateUserType = (newUserType) => {
    localStorage.setItem("userType", JSON.stringify(newUserType));
    setUserType(localStorage.getItem("userType"));
  };

  const updateContactNumber = (newContactNumber) => {
    localStorage.setItem("contactNumber", JSON.stringify(newContactNumber));
    setContactNumber(localStorage.getItem("contactNumber"));
  };

  const updateUserId = (newUserId) => {
    localStorage.setItem("userId", JSON.stringify(newUserId));
    setUserId(localStorage.getItem("userId"));
  };

  const updateAuthMode = (newAuthMode) => {
    localStorage.setItem("authMode", JSON.stringify(newAuthMode));
    setAuthMode(localStorage.getItem("authMode"));
  };

  const value = {
    user,
    setUser,
    contactNumber,
    setContactNumber,
    userType,
    setUserType,
    updateUser,
    updateUserType,
    updateContactNumber,
    userId,
    updateUserId,
    authMode,
    updateAuthMode,
  };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
}

export default UserProvider;
